import { useState } from 'react'
import { relationInfo } from './MapBoxHelpers'
import mapboxgl from 'mapbox-gl'

const usePaintPopupHandlers = (map) => {
  // Local state
  // Keep a handler-reference to unregister them when switching paint
  const [handlers, setHandlers] = useState({
    paintPopup: null,
    paintMouseMoveHandler: null,
    paintMouseLeaveHandler: null
  })

  const createPaintMouseMoveHandler = (
    map,
    propertyPath,
    labelName,
    popup
  ) => (e) => {
    const lngLat = e.lngLat
    let value

    // Check if the propertyPath is for relations or a regular property
    if (propertyPath.startsWith('relation.')) {
      // Extract names from all relations using the propertyPath
      const hasRelations = ('relations' in e.features[0].properties)
      if (hasRelations) {
        const relations = relationInfo(e.features[0], hasRelations)
        const relationNames = relations.map((relation) =>
          propertyPath.split('.').slice(1).reduce((obj, key) => obj && obj[key], relation)
        ).filter(name => name) // Filter out any undefined or null names

        // Join the names into a single string
        value = relationNames.join(', ')
      }
    } else {
      // Directly access the property if it's not related to relations
      value =
        propertyPath.split('.').reduce((obj, key) => obj && obj[key], e.features[0].properties)
    }

    // Only display popup if the property exists
    if (value !== undefined && value !== null) {
      map.getCanvas().style.cursor = 'pointer'
      popup.setLngLat(lngLat).setHTML(labelName + value).addTo(map)
    } else {
      popup.remove() // Remove popup if property doesn't exist
    }
  }

  const createPaintMouseLeaveHandler = (popup) => (e) => {
    popup.remove()
    map.getCanvas().style.cursor = ''
  }

  const createPaintPopup = () => {
    return new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: true
    })
  }

  const enterPopupMode = (map, layerId, propertyPath, labelName) => {
    const paintPopup = createPaintPopup()
    const paintMouseMoveHandler =
      createPaintMouseMoveHandler(map, propertyPath, labelName, paintPopup)
    const paintMouseLeaveHandler = createPaintMouseLeaveHandler(paintPopup)

    // Keep a handler-references to unregister them in exitPopupMode
    setHandlers({
      paintPopup,
      paintMouseMoveHandler,
      paintMouseLeaveHandler
    })

    // Register handler
    // "mousemove" fixes hover between two segments without leaving one
    map.on('mousemove', layerId, paintMouseMoveHandler)
    map.on('mouseleave', layerId, paintMouseLeaveHandler)
  }

  const exitPopupMode = (layerId) => {
    // Remove handler (avoids multi-handler-calls)
    const {
      paintPopup,
      paintMouseMoveHandler,
      paintMouseLeaveHandler
    } = handlers
    if (paintMouseMoveHandler !== null || paintMouseLeaveHandler !== null) {
      map.off('mousemove', layerId, paintMouseMoveHandler)
      map.off('mouseleave', layerId, paintMouseLeaveHandler)
    }
    if (paintPopup !== null) {
      paintPopup.remove()
    }

    // Update state
    setHandlers({
      paintPopup: null,
      paintMouseMoveHandler: null,
      paintMouseLeaveHandler: null
    })
  }

  return {
    enterPopupMode,
    exitPopupMode
  }
}

export default usePaintPopupHandlers
