/**
 * Mapbox styles representing different background maps.
 */
export const BackgrounStyles = {
  Light: {
    key: 'light',
    label: 'Helle Karte',
    style: 'mapbox://styles/mapbox/light-v11'
  },
  Streets: {
    key: 'streets',
    label: 'Straßenkarte',
    style: 'mapbox://styles/mapbox/streets-v12'
  },
  Satellite: {
    key: 'satellite',
    label: 'Satellitenkarte',
    style: 'mapbox://styles/mapbox/satellite-streets-v12'
  },
  Dark: {
    key: 'dark',
    label: 'Dunkle Karte',
    style: 'mapbox://styles/mapbox/dark-v11'
  },
  None: {
    key: 'none',
    label: 'Keine Karte',
    style: null
  }
}
