import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddTrafficMap from './AddTrafficMap'
import TrafficMaps from './TrafficMaps'

/**
 * Presentational component (View) for TrafficMaps.
 * - showns the list of TrafficMaps to the user
 * - allows the user to add TrafficMaps ("start Simulation")
 */
class TrafficMapsView extends Component {
  render () {
    const {
      map,
      logout,
      addFeature
    } = this.props

    return (
      <React.Fragment>
        <TrafficMaps
          map={map}
          logout={logout} />

        <AddTrafficMap
          map={map}
          logout={logout}
          addFeature={addFeature}/>
      </React.Fragment>
    )
  }
}

TrafficMapsView.propTypes = {
  addFeature: PropTypes.func.isRequired,
  map: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired
}

export default TrafficMapsView
