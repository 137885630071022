const blue = '#4472C4' // Provided by TUD 2024-11-05
const lightBlue = '#00B0F0' // Provided by TUD 2024-11-05
const green = '#70AD47' // Provided by TUD 2024-11-05
const lightGreen = '#A9D18E' // Provided by TUD 2024-11-05
// const darkGreen = '#388E3C'
const yellow = '#FFD966' // Provided by TUD 2024-11-05
const darkGreyYellow = '#CAAA5A' // Provided by TUD 2024-11-05
const lightOrange = '#F4B183' // Provided by TUD 2024-11-05
// const orange = '#FF9800'
const darkOrange = '#C55A11'
const red = '#F44336'
const grey = '#C9C9C9' // Provided by TUD 2024-11-05
const quickSilverGrey = '#A6A6A6' // Provided by TUD 2024-11-05

const RoadProperties = {
  unified: {
    key: 'unified',
    label: 'Einheitlich',
    options: [
      {
        label: 'Hinzugefügt',
        value: 'added',
        color: green
      },
      {
        label: 'Geändert',
        value: 'edited',
        color: yellow
      },
      {
        label: 'Gelöscht',
        value: 'deleted',
        color: red
      },
      {
        label: 'Unverändert',
        value: 'unchanged',
        color: grey
      }
    ]
  },
  relations: {
    key: 'relations',
    label: 'Radrouten',
    options: [
      {
        label: 'Radroute',
        value: 'bicycle', // RoadLegends needs this for unique keys
        // We could try to use the color from the relation but there can be two relations on one way
        color: blue
      }
    ]
  },
  maxSpeed: {
    key: 'maxSpeed',
    label: 'Höchstgeschwindigkeit des MIV',
    options: [
      {
        label: '<= 30 km/h',
        value: '<=30',
        color: green
      },
      {
        label: '31-50 km/h',
        value: '31-50',
        color: yellow
      },
      {
        label: '>50 km/h',
        value: '>50',
        color: red
      },
      {
        label: 'nA',
        value: 'nA',
        color: grey
      }
    ]
  },
  roadStyleSimplified: {
    key: 'roadStyleSimplified',
    label: 'Führungsform',
    options: [
      {
        label: 'Radschnellweg',
        value: 'CycleHighway',
        color: blue
      },
      {
        label: 'Fahrradstraße',
        value: 'BicycleRoad',
        color: lightBlue
      },
      {
        label: 'Separat geführter Radweg',
        value: 'BicycleWay',
        color: green
      },
      {
        label: 'Radfahr- oder Schutzstreifen',
        value: 'BicycleLane',
        color: lightGreen
      },
      {
        label: 'Busspur - Radverkehr frei',
        value: 'BusLane',
        color: yellow
      },
      {
        label: 'Gemeinsamer Geh- und Radweg',
        value: 'MixedWay',
        color: darkGreyYellow
      },
      {
        label: 'Keine Fahrradinfrastruktur',
        value: 'No',
        color: grey
      }
    ]
  },
  surface: {
    key: 'surface',
    label: 'Oberflächenbeschaffenheit',
    options: [
      {
        label: 'Komfortlevel 1',
        label2: '(z.B. Asphalt)',
        value: 'comfort_1',
        color: quickSilverGrey
      },
      {
        label: 'Komfortlevel 2',
        label2: '(z.B. verdichtete Deckschicht)',
        value: 'comfort_2',
        color: lightGreen
      },
      {
        label: 'Komfortlevel 3',
        label2: '(z.B. Kopfsteinpflaster)',
        value: 'comfort_3',
        color: lightOrange
      },
      {
        label: 'Komfortlevel 4',
        label2: '(z.B. Gras, loser grober Schotter)',
        value: 'comfort_4',
        color: darkOrange
      }
    ]
  }
}

export default RoadProperties
