/**
 * Endpoint names offered by the API.
 */
export const Endpoints = {
  Users: (userName) => `users/${userName}`,
  Area: 'area',
  BaseNet: 'base-net',
  EvaluationNet: 'evaluation-net',

  Scenarios: 'scenarios',
  Scenario: (scenarioId) => `scenarios/${scenarioId}`,
  ScenarioSimulation: (scenarioId) => `scenarios/${scenarioId}/simulate`,
  SimlationProgress: (scenarioId) => `scenarios/${scenarioId}/simulate/progress`,

  Differences: 'differences',
  Difference: (minuendId, subtrahendId) =>
    `differences/${minuendId}/${subtrahendId}`,
  DifferenceProcessing: (minuendId, subtrahendId) =>
    `differences/${minuendId}/${subtrahendId}/process`,
  DifferenceProgress: (minuendId, subtrahendId) =>
    `differences/${minuendId}/${subtrahendId}/process/progress`
}
