import { useState, useRef } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { setRoadPropertyStylesAction, setEditModeAction, deleteTrafficAction, deleteDifferenceAction } from '../../actions/defaultActions'
import { deleteDifference, deleteSimulation } from '../DataApi'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import RoadProperties from '../sidebar/scenario/RoadProperties'
import { RelationModifications } from '../constants/RelationModifications'
import { useSelector } from 'react-redux'
import { relationInfo } from './MapBoxHelpers'

const useMapFeatureHandlers = (map, differenceMaps, trafficMaps, logout) => {
  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  // Keep a handler-references to unregister them in exitEditMode
  const [handlers, setHandlers] = useState({
    segmentMouseMoveHandler: null,
    segmentMouseLeaveHandler: null,
    segmentClickHandler: null,
    mapClickHandler: null
  })

  // To access the current version of editMode in handlers.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const editModeContainer = useRef()
  editModeContainer.current = editMode
  const roadPropertyStylesContainer = useRef()
  roadPropertyStylesContainer.current = roadPropertyStyles

  const switchStyleColors = (scenario, roadPropertyStyles, map, dispatch) => {
    // Swap the colors for each style
    const newStyles = roadPropertyStyles.map(style => {
      const newStyle = cloneDeep(style) // original object may not be changed
      newStyle.colors = style.alternativeColors
      newStyle.opacity = style.alternativeOpacity
      newStyle.alternativeColors = style.colors
      newStyle.alternativeOpacity = style.opacity
      if (style.active) {
        map.setPaintProperty(scenario.layerId, 'line-color', newStyle.colors)
        map.setPaintProperty(scenario.layerId, 'line-opacity', newStyle.opacity)
      }
      return newStyle
    })
    dispatch(setRoadPropertyStylesAction(newStyles))
  }

  const isModifyingWay = (editMode) => editMode.wayEdit.clickedWayId !== null

  // Checks if the user is in a specific `RelationModifications` mode
  const isModifyingRelation = (editMode) =>
    editMode.relationEdit.clicked.relationId != null &&
    editMode.relationEdit.modification.type != null

  const createSegmentMouseLeaveHandler = (
    map,
    editModeContainer,
    dispatch,
    roadPropertyStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    // Reset cursor
    map.getCanvas().style.cursor = ''

    const active = roadPropertyStyles.find(style => style.active).key

    switch (active) {
      case RoadProperties.relations.key: {
        if (!isModifyingRelation(editMode)) {
          changeHoveredRelation(editMode, null, dispatch)
        } else {
          changeHoveredWay(editMode, null, dispatch)
        }
        break
      } default: {
        changeHoveredWay(editMode, null, dispatch)
      }
    }
  }

  const createSegmentMouseMoveHandler = (
    map,
    editModeContainer,
    dispatch,
    roadPropertyStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    const active = roadPropertyStyles.find(style => style.active).key
    const targetWay = e.features[0]

    switch (active) {
      case RoadProperties.relations.key: {
        if (!isModifyingRelation(editMode)) {
          // Set cursor
          const hasRelations = ('relations' in targetWay.properties)
          const newRelationInfo =
            hasRelations ? relationInfo(targetWay, hasRelations)[0] : null // select first relation

          const oldHoveredRelation = editMode.relationEdit.hovered
          const sameRelationHovered = oldHoveredRelation.relationId && newRelationInfo &&
            newRelationInfo.osmId === oldHoveredRelation.relationId
          if (sameRelationHovered) {
            return // relation did not change and should still be highlighted
          }

          const clickedRelationId = editMode.relationEdit.clicked.relationId
          const hoveredIsClicked =
            newRelationInfo && clickedRelationId && newRelationInfo.osmId === clickedRelationId
          if (newRelationInfo === null || hoveredIsClicked) {
            changeHoveredRelation(editMode, null, dispatch)
            // For some reasons the cursor does not reliably change for already clicked relations
            // Thus, we currently do not support to click already clicked relations
            return // has no relations or is not clickable
          }

          if (hasRelations) {
            map.getCanvas().style.cursor = 'pointer'
          }

          // Set new hovered relation
          const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
          const newRelation = {
            relationId: newRelationInfo.osmId,
            wayIds: relationWayIds,
            tags: newRelationInfo.tags
          }
          changeHoveredRelation(editMode, newRelation, dispatch)
        } else {
          if (wayNotSelectable(targetWay.id, editMode.relationEdit)) {
            return
          }

          // Set cursor
          map.getCanvas().style.cursor = 'pointer'

          changeHoveredWay(editMode, targetWay.id, dispatch)
        }
        break
      }
      default: {
        // Set cursor
        map.getCanvas().style.cursor = 'pointer'

        changeHoveredWay(editMode, targetWay.id, dispatch)
      }
    }
  }

  const createSegmentClickHandler = (
    map,
    editModeContainer,
    dispatch,
    roadPropertyStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (!editMode.active || isModifyingWay(editMode)) {
      return
    }

    const active = roadPropertyStyles.find(style => style.active).key
    const targetWay = e.features[0]

    if (active !== RoadProperties.relations.key) {
      const targetWayId = targetWay.properties['@id']

      changeHoveredWay(editMode, null, dispatch)
      changeClickedWay(editMode, targetWayId, dispatch)
    } else if (active === RoadProperties.relations.key && !isModifyingRelation(editMode)) {
      const hasRelations = ('relations' in targetWay.properties)
      if (!hasRelations) {
        return
      }

      const newRelationInfo =
        hasRelations ? relationInfo(targetWay, hasRelations)[0] : null // select first relation

      const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
      const clickedRelation = {
        relationId: newRelationInfo.osmId,
        wayIds: relationWayIds,
        tags: newRelationInfo.tags
      }

      changeHoveredRelation(editMode, null, dispatch)
      changeClickedRelation(editMode, clickedRelation, dispatch)
    } else if (active === RoadProperties.relations.key && isModifyingRelation(editMode)) {
      const selectedWayId = targetWay.properties['@id']
      if (wayNotSelectable(selectedWayId, editMode.relationEdit)) {
        return
      }

      const oldSelectedWayIds = editMode.relationEdit.modification.selectedWayIds
      const unSelectWay = oldSelectedWayIds.includes(selectedWayId)
      const newWayIds = unSelectWay
        ? oldSelectedWayIds.filter(id => id !== selectedWayId)
        : [...oldSelectedWayIds, selectedWayId]
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          modification: {
            ...editMode.relationEdit.modification,
            selectedWayIds: newWayIds
          }
        }
      }
      dispatch(setEditModeAction(newEditModeState, map, editMode))
    } else {
      throw Error('Expected active style: ' + active)
    }
  }

  /**
   * Resets currently clicked relation.
   */
  const createMapClickHandler = (
    map, dispatch, editModeContainer, roadPropertyStylesContainer
  ) => (e) => {
    // disabled as this is buggy

    /* const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (!editMode.active ||
      (isModifyingWay(editMode) && isModifyingRelation(editMode))) {
      return
    } */

    // Update state
    // const active = roadPropertyStyles.find(style => style.active).key
    /* if (active === RoadProperties.relations.key) {
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: {
            relationId: null, // automatically updates legend
            wayIds: [],
            tags: {}
          }
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))
    } */
  }

  const enterEditMode = (e, scenario, dispatch) => {
    const buttonClicked = e.target
    // Lock view
    setControlButtonsDisabled(true, buttonClicked) // FIXME
    setDropdownsDisabled(true) // FIXME
    switchStyleColors(scenario, roadPropertyStyles, map, dispatch)

    // If we access `onClickHandler` etc. via `this.state.onClickHandler` the handler is not found
    const segmentMouseMoveHandler = createSegmentMouseMoveHandler(
      map,
      editModeContainer,
      dispatch,
      roadPropertyStylesContainer
    )
    const segmentMouseLeaveHandler = createSegmentMouseLeaveHandler(
      map,
      editModeContainer,
      dispatch,
      roadPropertyStylesContainer
    )
    const segmentClickHandler = createSegmentClickHandler(
      map,
      editModeContainer,
      dispatch,
      roadPropertyStylesContainer
    )
    const mapClickHandler = createMapClickHandler(
      map,
      dispatch,
      editModeContainer,
      roadPropertyStylesContainer
    )

    // Keep a handler-references to unregister them in exitEditMode
    setHandlers({
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    })

    // Update state
    const newEditModeState = {
      active: true,
      scenarioChanged: false,
      scenario,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          type: null,
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))

    // Register handler
    const layerId = scenario.layerId
    map.on('click', mapClickHandler) // register before segment click, else clicked segment is reset
    map.on('click', layerId, segmentClickHandler)
    // "mousemove" fixes hover between two segments without leaving one
    map.on('mousemove', layerId, segmentMouseMoveHandler)
    map.on('mouseleave', layerId, segmentMouseLeaveHandler)

    // Attention! `map.on('click', e => {})` is also called when we click on a feature layer!
    // Thus, don't do anyhing there which could lead to a race condition with other onclick events!
  }

  const exitEditMode = async (e, dispatch) => {
    const buttonClicked = e.target
    const { scenario } = editModeContainer.current
    switchStyleColors(scenario, roadPropertyStyles, map, dispatch)

    // Remove handler (avoids multi-handler-calls)
    const {
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    } = handlers
    const layerId = scenario.layerId
    map.off('mousemove', layerId, segmentMouseMoveHandler)
    map.off('mouseleave', layerId, segmentMouseLeaveHandler)
    map.off('click', layerId, segmentClickHandler)
    map.off('click', mapClickHandler)

    // Unhighlight edited elements on map (saved)
    const source = map.getSource(scenario.sourceId)
    const sourceData = source._data
    sourceData.features.filter(element => element.properties.edited === true).forEach(element => {
      element.properties.edited = false
    })
    source.setData(sourceData)

    // Update state
    setHandlers({
      segmentMouseMoveHandler: null,
      segmentMouseLeaveHandler: null,
      segmentClickHandler: null,
      mapClickHandler: null
    })

    const scenarioChanged = editMode.scenarioChanged
    const newEditModeState = {
      active: false,
      scenarioChanged: false,
      scenario: null,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          type: null,
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))

    if (scenarioChanged) {
      // Remove DifferenceMaps bound to that Scenario
      for (const differenceMap of differenceMaps) {
        if (differenceMap.minuendId === scenario.id ||
          differenceMap.subtrahendId === scenario.id) {
          await deleteDifference(
            dispatch,
            defaultErrorHandling,
            logout,
            differenceMap.minuendId,
            differenceMap.subtrahendId
          )
          map.removeLayer(differenceMap.layerId)
          map.removeSource(differenceMap.sourceId)
          dispatch(deleteDifferenceAction(differenceMap.id))
        }
      }

      // Remove TrafficMaps bound to that Scenario
      for (const trafficMap of trafficMaps) {
        if (trafficMap.scenarioId === scenario.id) {
          await deleteSimulation(
            dispatch,
            defaultErrorHandling,
            logout,
            trafficMap.scenarioId
          )
          map.removeLayer(trafficMap.layerId)
          map.removeSource(trafficMap.sourceId)
          dispatch(deleteTrafficAction(trafficMap.id))
        }
      }
    }

    // Unlock view
    setControlButtonsDisabled(false, buttonClicked)
    setDropdownsDisabled(false)
  }

  // Only allow ways which are (not) in relationEdit.clicked.wayIds for remove (add)
  const wayNotSelectable = (selectedWayId, editModeRelation) => {
    const addingWays = editModeRelation.modification.type === RelationModifications.AddingWays ||
    editModeRelation.modification.type === RelationModifications.CreatingRelation
    const removingWays = editModeRelation.modification.type === RelationModifications.RemovingWays
    const wayInRelation = editModeRelation.clicked.wayIds.includes(selectedWayId)
    return (addingWays && wayInRelation) || (removingWays && !wayInRelation)
  }

  /**
   * Enables or disables all control buttons but one.
   *
   * @param newState True if the buttons should be disabled, false if enabled
   * @param exceptionButton The button to be kept untouched
   */
  const setControlButtonsDisabled = (newState, exceptionButton) => {
    const controlButtons = document.getElementsByClassName('controlButton')
    Array.prototype.slice.call(controlButtons)
      .filter(element => element !== exceptionButton)
      .forEach(button => {
        button.disabled = newState
      })
  }

  /**
   * Enables or disables all dropdowns.
   *
   * @param newState True if the dropdowns should be disabled, false if enabled
   */
  const setDropdownsDisabled = (newState) => {
    const buttons = document.getElementsByClassName('dropdownButton')
    Array.prototype.slice.call(buttons)
      .forEach(button => {
        button.disabled = newState
      })
  }

  const findWayIds = (editMode, relationId) => {
    const { scenario } = editMode
    const source = map.getSource(scenario.sourceId)
    const features = source._data.features
    return features.filter((feature) => {
      return ('relations' in feature.properties) &&
        feature.properties.relations.some((relation) => relation.osmId === relationId)
    }).map((feature) => feature.properties['@id'])
  }

  const changeHoveredRelation = (editMode, newRelation, dispatch) => {
    const oldRelation = editMode.relationEdit.hovered
    if (newRelation == null || (newRelation && oldRelation.relationId !== newRelation.relationId)) {
      dispatch(setEditModeAction({
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          hovered: newRelation || {
            relationId: null,
            wayIds: [],
            tags: {}
          }
        }
      }, map, editMode))
    }
  }

  // Keep function in sync with copy in EditRelation
  const changeClickedRelation = (editMode, newRelation, dispatch) => {
    dispatch(setEditModeAction({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: newRelation
      }
    }, map, editMode))
  }

  const changeHoveredWay = (editMode, newWayId, dispatch) => {
    dispatch(setEditModeAction({
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        hoveredWayId: newWayId
      }
    }, map, editMode))
  }

  const changeClickedWay = (editMode, newWayId, dispatch) => {
    dispatch(setEditModeAction({
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        clickedWayId: newWayId
      }
    }, map, editMode))
  }

  return {
    enterEditMode,
    exitEditMode
  }
}

export default useMapFeatureHandlers
