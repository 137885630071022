import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setMapStyleAction } from '../../../actions/defaultActions'
import { BackgrounStyles } from './BackgroundStyles'
import { layerPrefix } from '../../IdHelper'
import { styled } from '@mui/material'
import Dropdown from '../../sidebar/Dropdown'

const BackgroundSwitcher = ({ map }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const mapStyle = useSelector(state => state.mapStyle)

  // Local state
  const [mapLayerVisible, setMapLayerVisible] = useState(true)

  const toggleBackground = (visibility) => {
    const layers = map.getStyle().layers

    layers.forEach(layer => {
      if (layer.id && !layer.id.startsWith(layerPrefix)) {
        map.setLayoutProperty(layer.id, 'visibility', visibility)
      }
    })

    setMapLayerVisible(!mapLayerVisible)
  }

  const switchStyle = (selection) => {
    const noMap = !selection.style
    // When switching "no map" it's expected, that the background is bright [BIK-1172]
    // So we first switch the style to "bright" and then disable the background
    if (noMap) {
      selection = BackgrounStyles.Light
    }

    // Save the current radsim layers to add them again when switching the style
    const radsimLayers = map.getStyle().layers.filter(layer => layer.id.startsWith(layerPrefix))
    const radsimSources = Object.entries(map.getStyle().sources)
      .filter(([id]) => id.startsWith(layerPrefix))

    // Warning "Expected value to be of type string, but found null instead."
    // can probably be ignored, happens with style "Street" and "Outdoor" (removed) only.
    map.setStyle(selection.style)

    map.once('styledata', () => {
      radsimSources.forEach(([id, source]) => {
        if (!map.getSource(id)) {
          map.addSource(id, source)
        }
      })

      radsimLayers.forEach(layer => {
        if (!map.getLayer(layer.id)) {
          map.addLayer(layer)
        }
      })

      if (noMap) {
        const newVisibility = mapLayerVisible ? 'none' : 'visible'
        toggleBackground(newVisibility) // also executes setMapLayerVisible
        // setMapLayerVisible(!mapLayerVisible)
      } else {
        setMapLayerVisible(true)
      }
    })

    const newMapStyle = noMap ? BackgrounStyles.None.key : selection.key
    dispatch(setMapStyleAction(newMapStyle))
  }

  const dropdownOptions = Object.values(BackgrounStyles).map(style => ({
    id: style.key,
    name: style.label,
    layerId: style.key
  }))

  const handleChange = (selectedOption) => {
    const selectedStyle = Object.values(BackgrounStyles).find(
      style => style.key === selectedOption.id
    )
    switchStyle(selectedStyle)
  }

  const selectedStyle = dropdownOptions.find(option => option.id === mapStyle) || null

  return (
    <BackgroundSwitcherContainer>
      <Dropdown
        width='175px'
        height='36px'
        layerObjects={dropdownOptions}
        onChangeHandler={handleChange}
        triggerText="Stil wählen"
        selectedLayerObject={selectedStyle}
        textAlign='left'
        border='0px'
        boxShadow='rgba(0, 0, 0, 0.1) 0px 0px 10px'
        marginToOptions='2px'
      />
    </BackgroundSwitcherContainer>
  )
}

BackgroundSwitcher.propTypes = {
  map: PropTypes.object.isRequired
}

const BackgroundSwitcherContainer = styled('div')({
  position: 'absolute',
  top: '78px',
  left: '398px'
})

export default BackgroundSwitcher
