import React/*, { useState } */ from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setRoadPropertyStyleActiveAction } from '../../actions/defaultActions'
import { differenceLayerPrefix, evaluationLayerPrefix, trafficLayerPrefix } from '../IdHelper'
import { useSnackbarContext } from '../SnackbarContext.js'
import RoadProperties from './scenario/RoadProperties.js'
import usePaintPopupHandlers from '../map/usePaintPopupHandlers.js'
import { styled/*, Tabs, Tab, Box */ } from '@mui/material'

const PaintSwitcher = ({ map }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  const { enterPopupMode, exitPopupMode } = usePaintPopupHandlers(map)

  // Tab state
  /* const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  } */

  const setStyle = (style) => {
    if (editMode.relationEdit.clicked.relationId != null &&
      editMode.relationEdit.modification.type != null) {
      enqueueSnackbar('Beenden Sie zuerst die Routenbearbeitung', { autoHideDuration: 3000 })
      return
    }

    // Adjust tooltips:
    if (style.key === RoadProperties.relations.key) {
      enterPopupMode(map, visibleLayerId, 'relation.tags.name', '') // no label: 1+ relation names
    } else {
      exitPopupMode(visibleLayerId)
    }

    // Adjust layer style
    map.setPaintProperty(visibleLayerId, 'line-width', style.lineWidth)
    map.setPaintProperty(visibleLayerId, 'line-color', style.colors)
    map.setPaintProperty(visibleLayerId, 'line-opacity', style.opacity)
    map.setPaintProperty(visibleLayerId, 'line-dasharray', style.lineDashArray)

    // The action also unsets the clicked relation internally
    const previouslyActiveKey = roadPropertyStyles.find(s => s.active).key
    dispatch(
      setRoadPropertyStyleActiveAction(style.key, map, previouslyActiveKey, editMode, dispatch)
    )
  }

  const isTrafficShown = visibleLayerId !== null && visibleLayerId.startsWith(trafficLayerPrefix)
  const isDiffShown = visibleLayerId !== null && visibleLayerId.startsWith(differenceLayerPrefix)
  const isEvaluationShown =
    visibleLayerId !== null && visibleLayerId.startsWith(evaluationLayerPrefix)
  const isScenarioShown =
    visibleLayerId !== null && !isTrafficShown && !isDiffShown && !isEvaluationShown

  return isScenarioShown
    ? (
    <LayerSwitcher>
      {/* <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Attribut" />
        <Tab label="Hintergrund" />
      </Tabs>
      <TabContent activeTab={activeTab} index={0}> */}
      <Title>Anzeige</Title>
      {roadPropertyStyles.map((style) => (
        <a
          href="#"
          key={style.key}
          className={style.active ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation() // is this needed?
            setStyle(style)
          }}
        >
          {style.label}
        </a>
      ))}
      {/* </TabContent>
      <TabContent activeTab={activeTab} index={1}>
        <p>This is other content that could be displayed in another tab.</p>
      </TabContent> */}
    </LayerSwitcher>
      )
    : null
}

/* const TabContent = ({ children, activeTab, index }) => {
  return activeTab === index ? <Box p={2}>{children}</Box> : null
}

TabContent.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
} */

const Title = styled('div')({
  fontSize: '15pt',
  color: '#222A35',
  paddingBottom: '10px'
})

PaintSwitcher.propTypes = {
  map: PropTypes.object // may be null until map is intialized
}

const LayerSwitcher = styled('div')({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  '& a': {
    display: 'block',
    padding: '5px 10px',
    color: '#333',
    textDecoration: 'none'
  },
  '& a.active': {
    backgroundColor: '#0074d9',
    color: '#fff'
  }
})

export default PaintSwitcher
