import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddDifferenceMap from './AddDifferenceMap'
import DifferenceMaps from './DifferenceMaps'

/**
 * Presentational component (View) for DifferenceMaps.
 * - showns the list of DifferenceMaps to the user
 * - allows the user to add DifferenceMaps ("calculate difference")
 */
class DifferenceMapsView extends Component {
  render () {
    const {
      map,
      logout,
      addFeature
    } = this.props

    return (
      <div>
        <DifferenceMaps
          map={map}
          logout={logout} />
        <AddDifferenceMap
          map={map}
          logout={logout}
          addFeature={addFeature} />
      </div>
    )
  }
}

DifferenceMapsView.propTypes = {
  addFeature: PropTypes.func.isRequired,
  map: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired
}

export default DifferenceMapsView
