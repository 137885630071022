import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

class DifferenceLegend extends Component {
  render () {
    // const lowPositiveTrafficGreen = 'rgb(208,249,209)'
    const positiveTrafficGreen = 'rgb(0,235,0)'
    // const lowNegativeTrafficRed = 'rgb(249,208,209)'
    const negativeTrafficRed = 'rgb(235,0,0)'
    const neutralTrafficGrey = 'rgb(200,200,200)'
    const differenceThresholds = this.props.differenceThresholds
    return <LegendBox>
      <Header>Verkehrsmengen</Header>
      <LineWrapper>
        <Line height='12px' color={positiveTrafficGreen} />
        <Description>&gt; {differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='10px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[3]} bis {differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='8px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[2]} bis {differenceThresholds[3]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='6px' color={positiveTrafficGreen}/>
        <Description>{differenceThresholds[1]} bis {differenceThresholds[2]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='4px' color={positiveTrafficGreen} />
        <Description>{differenceThresholds[0]} bis {differenceThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={positiveTrafficGreen}/>
        <Description>1 bis {differenceThresholds[0]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={neutralTrafficGrey}/>
        <Description>0</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='2px' color={negativeTrafficRed}/>
        <Description>-1 bis -{differenceThresholds[0]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='4px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[0]} bis -{differenceThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='6px' color={negativeTrafficRed}/>
        <Description>-{differenceThresholds[1]} bis -{differenceThresholds[2]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='8px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[2]} bis -{differenceThresholds[3]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='10px' color={negativeTrafficRed} />
        <Description>-{differenceThresholds[3]} bis -{differenceThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height='12px' color={negativeTrafficRed} />
        <Description>&lt; -{differenceThresholds[4]}</Description>
      </LineWrapper>
    </LegendBox>
  }
}

DifferenceLegend.propTypes = {
  differenceThresholds: PropTypes.array.isRequired
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '155px',
  height: '340px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '70px',
  padding: '10px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

const Header = styled('div')({
  fontSize: '15px',
  padding: '10px'
})

const Line = styled('div')(({ height, color }) => ({
  height,
  width: '30px',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '20px'
}))

const LineWrapper = styled('div')({
  marginLeft: '10px',
  marginTop: '0px'
})

const Description = styled('div')({
  display: 'inline-block',
  fontSize: '12px'
})

export default DifferenceLegend
