import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const Sidebar = ({ children, bottom }) => {
  return (
    <StyleSidebar>
      { /* TODO: add sidebar menu buttom */ }
      <Container>
        {children}
        <Bottom>{bottom}</Bottom>
      </Container>
    </StyleSidebar>
  )
}

Sidebar.propTypes = {
  bottom: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

const StyleSidebar = styled('div')({
  borderRight: '1px solid #222A35',
  overflowY: 'auto'
})

const Container = styled('div')({
  flexDirection: 'column',
  position: 'fixed', // relative to the viewport
  top: '70px',
  zIndex: '997',
  /* zIndex: $mobileView ? '999' : 'auto', */ // refer to RFR-665
  /* height: 'calc(100% - 69px)', */
  height: 'calc(100vh - 69px - 243px)', // minus Header/Bottom
  overflowY: 'auto',
  width: '350px',
  maxWidth: '80%',
  backgroundColor: '#FFFFFF',
  fontFamily: "'Helvetica', 'Arial', sans-serif",
  lineHeight: 1.5,
  color: '#222A35',
  borderRight: '1px solid #222A35'
})

const Bottom = styled('div')({
  position: 'fixed',
  bottom: '0px',
  width: '350px',
  height: '243px',
  backgroundColor: 'white',
  borderRight: '1px solid #222A35'
})

export default Sidebar
