import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import logo from '../../resources/logo.png'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { setDevModeAction } from '../../actions/defaultActions'
import { useDispatch } from 'react-redux'

/**
 * The top bar of the dashboard.
 */
const Header = ({ right, mobileView }) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Local state
  const [devModeclickCount, setDevModeClickCount] = useState(0)

  // Effects
  useEffect(() => {
    if (devModeclickCount === 10) {
      dispatch(setDevModeAction(true))
      console.log('Developer mode activated!')
    }
  }, [devModeclickCount, dispatch])

  const handleLogoClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent

    // Check if click is within the defined area (the "hole" in the R)
    if (
      offsetX >= 6 && offsetX <= 14 &&
      offsetY >= 6 && offsetY <= 14
    ) {
      setDevModeClickCount((prevCount) => prevCount + 1)
    }
  }

  return (
    <AppBar position="static" sx={
      {
        height: '70px',
        backgroundColor: '#222A35',
        borderBottom: '1px solid #005DA9'
      }}>
      <Toolbar sx={{
        alignItems: 'center',
        height: '70px',
        '& img': {
          height: '27px',
          marginRight: '10px',
          marginLeft: mobileView ? '71px' : '0px'
        }
      }}>
        {/* left */}
        <img
          alt='RadSim Logo'
          src={logo}
          onClick={handleLogoClick}
        />

        {/* flexGrow 1 ensures the Typografie-Container uses the remaining space */}
        <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }} />

        {/* right */}
        {right}
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  right: PropTypes.object.isRequired,
  mobileView: PropTypes.bool.isRequired
}

export default Header
