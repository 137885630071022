import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { addDifferenceAction, addScenarioAction, setVisibleLayerIdAction } from '../../../actions/defaultActions.js'
import { postScenarios, getScenario, getEvaluationNet } from '../../DataApi.js'
import { useSnackbarContext } from '../../SnackbarContext.js'
import ControlButton from '../../ControlButton.js'
import Dropdown from '../Dropdown.js'
import { MapLayers } from '../../constants/MapLayers.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers.js'
import { getEvaluationId, getLayerId } from '../../IdHelper.js'

export const evaluationScenarioName = 'Evaluation'

/**
 * Allows to define a new scenario based on another scenario.
 */
const AddScenario = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const scenarios = useSelector((state) => state.scenarios)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)
  const devMode = useSelector((state) => state.devMode)

  // Local state
  const [open, setOpen] = useState(false)
  const [draft, setDraft] = useState({ name: '' })
  const [selectedDropdownLayerObject, setSelectedDropdownLayerObject] = useState(null)
  const [devModeEnabled, setDevModeEnabled] = useState(false)

  // Effects
  useEffect(() => {
    const loadEvaluationNet = async () => {
      enqueueSnackbar('Lade Evaluationsmatrix ...')

      // Load evaluationNet
      const evaluationNet = (await getEvaluationNet(dispatch, defaultErrorHandling, logout)).data

      // Fill missing attributes
      const id = getEvaluationId(evaluationNet.scenarioId) // must start with evaluation_*
      const layerId = getLayerId(id) // must start with radsim_evaluation_*
      evaluationNet.id = id
      evaluationNet.name = evaluationScenarioName
      evaluationNet.editable = false
      evaluationNet.layerId = layerId
      evaluationNet.sourceId = layerId
      evaluationNet.networkGeometry.features.forEach(f => {
        f.properties.traffic = f.properties.trafficDifference
      })

      // add feature to map
      addFeature(map, layerId, evaluationNet.networkGeometry, MapLayers.Evaluation)

      // Add network to app
      dispatch(addDifferenceAction(evaluationNet))

      // From UIX view it makes sense to show the new layer
      // but this currently only fires a redux action but not the reducer -->
      dispatch(setVisibleLayerIdAction(layerId, map, visibleLayerId, roadPropertyStyles))
      closeSnackbar()
    }

    if (devMode && !devModeEnabled) {
      setDevModeEnabled(true)
      loadEvaluationNet()
    }
  }, [
    devMode,
    addFeature,
    closeSnackbar,
    enqueueSnackbar,
    dispatch,
    logout,
    map,
    roadPropertyStyles,
    visibleLayerId,
    scenarios,
    devModeEnabled
  ])

  const handlePopupSubmit = async (e) => {
    e.preventDefault()
    const templateId = selectedDropdownLayerObject.id
    if (draft.name.length === 0) {
      alert('Bitte geben Sie einen Namen ein.')
      return
    }
    if (scenarios.find(network => network.name === draft.name) !== undefined) {
      alert('Es existiert bereits ein Netz mit diesem Namen.')
      return
    }
    // Reset popup fields and hide to prevent user interaction
    setOpen(false)
    setDraft({ name: '' })
    setSelectedDropdownLayerObject(null)

    // Create scenario
    enqueueSnackbar('Erzeuge ' + draft.name)
    const createResult = await createScenario(draft.name, templateId)
    if (createResult instanceof Error) {
      // Replace this with Sentry later
      enqueueSnackbar(createResult.info)
      console.error(createResult.stack)
      return
    }
    const scenarioId = createResult

    // Load scenario
    const scenario = (await getScenario(dispatch, defaultErrorHandling, logout, scenarioId)).data

    // Fill missing attributes
    const layerId = getLayerId(scenarioId)
    draft.id = scenarioId
    draft.editable = true
    draft.layerId = layerId
    draft.sourceId = layerId

    // add feature to map
    addFeature(map, layerId, scenario.networkGeometry, MapLayers.Scenario)

    // Add network to app
    dispatch(addScenarioAction(draft))

    // From UIX view it makes sense to show the new layer
    // but this currently only fires a redux action but not the reducer -->
    dispatch(setVisibleLayerIdAction(layerId, map, visibleLayerId, roadPropertyStyles))
    closeSnackbar()
  }

  /**
   * Creates a new scenario at the API and returns it's identifier if successful.
   *
   * @param {*} name the name of the scenario to create
   * @param {*} templateId the id of the scenario to use as template
   * @returns the id of the scenario created or an {@code instanceof} {@code Error} if failed.
   */
  const createScenario = async (name, templateId) => {
    const response =
      await postScenarios(dispatch, defaultErrorHandling, logout, name, templateId)

    const location = response.headers.location
    if (location == null) {
      return new Error('Missing location header in server reply.')
    }
    const scenariosPath = '/scenarios/'
    const index = location.match(scenariosPath).index
    if (index == null || index === 0) {
      return new Error('Unexpected location header: ' + location)
    }
    const scenarioId = location.substr(index + scenariosPath.length)
    return scenarioId
  }

  /**
   * Updates the internal state.
   */
  const onNameChanged = (e) => {
    setDraft({ name: e.target.value })
  }

  /**
   * Updates internal state when the dropdown selection changes.
   */
  const onDropdownChange = (layerObject) => {
    setSelectedDropdownLayerObject(layerObject)
  }

  /**
   * Shows a popup for further input information.
   */
  const onAddButtonClicked = () => {
    setOpen(true)
  }

  return (
    <>
      <ControlButton
        text='Neues Netz erstellen'
        width='100%'
        icon='call_split'
        onClick={onAddButtonClicked}
        sx={{ marginTop: '9px' }} />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Neues Netzwerk hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name des neuen Netzes"
            type="text"
            fullWidth
            variant="outlined"
            value={draft.name}
            onChange={onNameChanged}
          />
          <Typography variant="body2" color="red" sx={{ marginBottom: '20px' }}>
            Nur Buchstaben+Zahlen, keine Sonderzeichen benutzen!
          </Typography>
          <Dropdown
            width='100%'
            triggerText='Netzgrundlage wählen'
            selectedLayerObject={selectedDropdownLayerObject}
            layerObjects={scenarios.filter(s => s.name !== evaluationScenarioName).map(scenario => {
              const layerObject = {}
              layerObject.id = scenario.id
              layerObject.name = scenario.name
              layerObject.layerId = scenario.id
              layerObject.scenarioId = scenario.id
              return layerObject
            })}
            onChangeHandler={onDropdownChange} />
        </DialogContent>
        <DialogActions sx={{ margin: '0px 15px 10px 15px' }}>
          <ControlButton
            width='300px'
            sx={{ margin: '0px' }}
            text='Speichern'
            disabled={selectedDropdownLayerObject === null}
            onClick={handlePopupSubmit}
          />
          <ControlButton
            width='300px'
            sx={{ margin: '0px' }}
            text='Schließen'
            onClick={() => setOpen(false)} />
          </DialogActions>
      </Dialog>
    </>
  )
}

AddScenario.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default AddScenario
