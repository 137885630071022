import React from 'react'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'

/**
 * A button which allows the user to log out.
 */
const LogoutButton = ({ logout }) => {
  return (
    <ControlButton
      onClick={logout}
      active={false}
      icon="power_settings_new"
      tooltip="Abmelden"
      sx={{ margin: '0px' }}
    />
  )
}

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired
}

export default LogoutButton
