import { Server as MirageServer } from 'miragejs'
import { getWebsocketApiUrl } from './components/login/utils'
import { Endpoints } from './components/constants/Endpoints'
const WebSocketServer = require('mock-socket')

// `mock-socket` does not support wildcast for scenarioId, so we only support one id:
export const TEST_PROGRESS_SCENARIO_ID = '000testScenarioId0000000'
export const TEST_PROGRESS_SCENARIO_ID_1 = '111testScenarioId1111111'

// Helper function to create a WebSocket server for a given scenario ID
const createProgressWebSocketServer = (endpoint) => {
  return new WebSocketServer.Server(getWebsocketApiUrl() + endpoint)
    .on('connection', (socket) => {
      console.log(`Progress connection established for ${endpoint}`)

      // Progress stages to send in sequence
      const progressStages = [0, 33, 66, 100]
      let currentStage = 0

      // Interval to send each progress update every second
      const intervalId = setInterval(() => {
        const progress = progressStages[currentStage]
        socket.send(JSON.stringify(progress)) // Send the progress

        // Close the WebSocket on 100% progress
        if (progress === 100) {
          socket.close()
          clearInterval(intervalId)
          console.log(`Progress complete for ${endpoint}, closing WebSocket.`)
        }

        currentStage += 1 // Move to the next stage
      }, 1000)
    })
}

/**
 * This mock-server extends the functionality of MiragaServer with
 * the ability to mock websocket server.
 */
class Server extends MirageServer {
  constructor (options = {}) {
    super(options)

    // this is needed to make sure mock-socket is not included in production build [RFR-649]
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      // Initialize WebSocket servers for both scenario IDs (baseNet and possible plan net created)
      this.jobsWebSocketServer1 = createProgressWebSocketServer(
        Endpoints.SimlationProgress(TEST_PROGRESS_SCENARIO_ID)
      )
      this.jobsWebSocketServer2 = createProgressWebSocketServer(
        Endpoints.SimlationProgress(TEST_PROGRESS_SCENARIO_ID_1)
      )
      // And for the difference endpoints (base - plan) and (plan - base)
      this.jobsWebSocketServer1 = createProgressWebSocketServer(
        Endpoints.DifferenceProgress(TEST_PROGRESS_SCENARIO_ID, TEST_PROGRESS_SCENARIO_ID_1)
      )
      this.jobsWebSocketServer2 = createProgressWebSocketServer(
        Endpoints.DifferenceProgress(TEST_PROGRESS_SCENARIO_ID_1, TEST_PROGRESS_SCENARIO_ID)
      )
    }
  }
}

export default Server
