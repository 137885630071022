import { MapLayers } from '../constants/MapLayers'
import { getDiffId, getLayerId } from '../IdHelper'

/**
 * Zoom 10 selected so that segments are still rendered when the viewport is set to the bounding box
 * where a large city like Berlin can be seen as a whole.
 */
export const OVERVIEW_ZOOM_LEVEL = 10

/**
 * Commonly used functions for MapBox API.
 */

export const addBaseNet = (map, baseNet, addFeature) => {
  addFeature(
    map,
    getLayerId(baseNet.scenarioId),
    baseNet.networkGeometry,
    MapLayers.Scenario
  )
}

export const addScenarios = (map, scenarios, addFeature) => {
  for (const scenario of scenarios) {
    const scenarioId = scenario._id
    const layerId = getLayerId(scenarioId)
    addFeature(map, layerId, scenario.networkGeometry, MapLayers.Scenario)
  }
}

export const addSimulations = async (map, simulations, addFeature) => {
  for (const simulation of simulations) {
    const layerId = getLayerId(simulation.id)
    const simulationData = await simulation.data
    addFeature(map, layerId, simulationData, MapLayers.TrafficMap)
  }
}

export const addDifferences = async (map, differences, addFeature) => {
  for (const difference of differences) {
    const diffId = getDiffId(difference.minuendId, difference.subtrahendId)
    const layerId = getLayerId(diffId)
    const differenceData = await difference.data
    addFeature(map, layerId, differenceData, MapLayers.DifferenceMap)
  }
}

export const getLineWidth = (dynamicWidth, relationScaling) => {
  const dynamicLineWidth = dynamicWidth !== null && dynamicWidth
  const baseWidth = dynamicLineWidth ? dynamicWidth : 0.8
  const baseZoom = dynamicLineWidth ? 10 : 13
  const base = dynamicLineWidth ? 1.5 : 2.0
  return createLineWidth(base, baseWidth, baseZoom, relationScaling)
}

const createLineWidth = (base, width, zoom, relationScaling = false) => {
  // Relations have over-regional importance, so the line needs to be thicker when zooming out
  if (relationScaling) {
    return [
      'interpolate', ['exponential', base], ['zoom'],
      0, ['*', 6, width, Math.pow(base, (0 - zoom))],
      11.49, ['*', 6, width, Math.pow(base, (12.5 - zoom))], // 6x width at zoom < 11.5 (2 km scale)
      11.5, ['*', 4, width, Math.pow(base, (12.5 - zoom))],
      12.49, ['*', 4, width, Math.pow(base, (12.5 - zoom))], // 4x width at zoom < 12.5 (1 km scale)
      12.5, ['*', 2, width, Math.pow(base, (12.5 - zoom))],
      14.09, ['*', 2, width, Math.pow(base, (14 - zoom))], // 2x width at zoom < 14 (300 m on scale)
      14.1, ['*', width, Math.pow(base, (14 - zoom))],
      24, ['*', width, Math.pow(base, (24 - zoom))]
    ]
  } else {
    // Normal scaling
    return [
      'interpolate', ['exponential', base], ['zoom'],
      0, ['*', width, Math.pow(base, (0 - zoom))],
      24, ['*', width, Math.pow(base, (24 - zoom))]
    ]
  }
}

export const relationInfo = (eventWay, hasRelations) => {
  // Nested properties are stringified in sourceData, so we decode it
  // See https://github.com/mapbox/mapbox-gl-js/issues/2434
  return hasRelations
    ? JSON.parse(eventWay.properties.relations)
    : null
}
