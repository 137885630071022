import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import AddScenario from './AddScenario'
import Scenarios from './Scenarios'
import EditFeature from './EditFeature'

const StyleBox = styled('div')({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  fontSize: '14pt'
})

const StyleHead = styled('div')({
  fontSize: '15pt',
  color: '#222A35',
  paddingBottom: '10px'
})

const ScenariosView = ({ map, logout, addFeature }) => {
  return (
    <div>
      <StyleBox>
        <StyleHead>Netze</StyleHead>

        <Scenarios
          map={map}
          logout={logout} />

        <AddScenario
          map={map}
          logout={logout}
          addFeature={addFeature} />
      </StyleBox>

      <EditFeature
        map={map}
        logout={logout} />
    </div>
  )
}

ScenariosView.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default ScenariosView
