import { createAction } from '@reduxjs/toolkit'
import { differenceLayerPrefix, evaluationLayerPrefix, trafficLayerPrefix } from '../components/IdHelper'
import RoadProperties from '../components/sidebar/scenario/RoadProperties'

/**
 * Creates an actions for updating the editMode in the Redux store.
 *
 * @param {*} editMode the new editModeState
 */
export const setEditModeAction =
  createAction('SET_EDIT_MODE', (editMode, map, previousEditMode) => {
    const sourceId = editMode.scenario
      ? editMode.scenario.sourceId
      : previousEditMode.scenario.sourceId

    // When hovered, clicked or selected wayIds are changed, update the map

    // wayEdit
    const previousHoveredWayId = previousEditMode.wayEdit.hoveredWayId
    const hoveredWayId = editMode.wayEdit.hoveredWayId
    switchFeatureState(map, sourceId, previousHoveredWayId, hoveredWayId, 'hovered')
    const previousClickedWayId = previousEditMode.wayEdit.clickedWayId
    const clickedWayId = editMode.wayEdit.clickedWayId
    switchFeatureState(map, sourceId, previousClickedWayId, clickedWayId, 'clicked')

    // relationEdit
    const previousHoveredRelationWayIds = previousEditMode.relationEdit.hovered.wayIds
    const hoveredRelationWayIds = editMode.relationEdit.hovered.wayIds
    switchFeaturesState(
      map,
      sourceId,
      previousHoveredRelationWayIds,
      hoveredRelationWayIds,
      'hovered'
    )
    const previousClickedRelationWayIds = previousEditMode.relationEdit.clicked.wayIds
    const clickedRelationWayIds = editMode.relationEdit.clicked.wayIds
    switchFeaturesState(
      map,
      sourceId,
      previousClickedRelationWayIds,
      clickedRelationWayIds,
      'clicked'
    )

    // relationEdit modification
    const previousHoveredModificationWayId = previousEditMode.relationEdit.modification.hoveredWayId
    const hoveredModificationWayId = editMode.relationEdit.modification.hoveredWayId
    switchFeatureState(
      map,
      sourceId,
      previousHoveredModificationWayId,
      hoveredModificationWayId,
      'hovered'
    )
    const previousSelectedWayIds = previousEditMode.relationEdit.modification.selectedWayIds
    const selectedWayIds = editMode.relationEdit.modification.selectedWayIds
    switchFeaturesState(
      map,
      sourceId,
      previousSelectedWayIds,
      selectedWayIds,
      'selected'
    )

    return {
      // TODO: We might update the whole editMode here, consider only applying the changes
      // Also: We might execute the whole logic above for all editMode changes
      // Instead, only insert the changes via dispatch(setEditMode({hoveredId: newId}))
      // And then only apply the map updates here if ('hoveredId' in payload.editModeUpdate)
      // We might also first want to switch to `createSlice` instead of creating actions manually
      // and then move this action logic to the reducers, see cyface-de/web-app
      payload: editMode
    }
  })

const switchFeatureState = (map, sourceId, previousWayId, newWayId, propertyName) => {
  if (previousWayId !== newWayId) {
    // Reset previous way
    if (previousWayId !== null) {
      map.setFeatureState({ source: sourceId, id: previousWayId }, { [propertyName]: false })
    }
    // Highlight new way
    if (newWayId !== null) {
      map.setFeatureState({ source: sourceId, id: newWayId }, { [propertyName]: true })
    }
  }
}

const switchFeaturesState = (map, sourceId, previousWayIds, newWayIds, propertyName) => {
  // Reset previous ways
  previousWayIds.forEach(previousWayId => {
    if (!newWayIds.includes(previousWayId)) {
      map.setFeatureState({ source: sourceId, id: previousWayId }, { [propertyName]: false })
    }
  })
  // Highlight new ways
  newWayIds.forEach(newWayId => {
    if (!previousWayIds.includes(newWayId)) {
      map.setFeatureState({ source: sourceId, id: newWayId }, { [propertyName]: true })
    }
  })
}

export const setVisibleLayerIdAction =
  createAction('SET_VISIBLE_LAYER_ID', (
    visibleLayerId,
    map,
    previousLayerId,
    roadPropertyStyles
  ) => {
    if (previousLayerId === undefined) {
      throw Error('PreviousLayerId is undefined')
    }
    if (map === null) {
      throw Error('SET_VISIBLE_LAYER_ID: Map is null')
    }
    // Hide previous layer
    if (previousLayerId !== null) {
      map.setLayoutProperty(previousLayerId, 'visibility', 'none')
    }
    // Make new layer visible and update colors
    if (visibleLayerId !== null) {
      if (!visibleLayerId.startsWith(trafficLayerPrefix) &&
        !visibleLayerId.startsWith(differenceLayerPrefix) &&
        !visibleLayerId.startsWith(evaluationLayerPrefix)) {
        const activeStyle = roadPropertyStyles.find(s => s.active)
        map.setPaintProperty(visibleLayerId, 'line-color', activeStyle.colors)
        map.setPaintProperty(visibleLayerId, 'line-opacity', activeStyle.opacity)
        map.setPaintProperty(visibleLayerId, 'line-dasharray', activeStyle.lineDashArray)
      }
      map.setLayoutProperty(visibleLayerId, 'visibility', 'visible')
    }
    return {
      payload: {
        visibleLayerId
      }
    }
  })

export const setRoadPropertyStyleActiveAction = createAction(
  'SET_ROAD_PROPERTY_STYLE_ACTIVE',
  (activeKey, map, previouslyActiveKey, editMode, dispatch) => {
    // If switching away from `relations` view, unset clicked relation
    const relationsKey = RoadProperties.relations.key
    if (previouslyActiveKey === relationsKey && activeKey !== relationsKey &&
      editMode.relationEdit.clicked.relationId != null &&
      editMode.relationEdit.modification.type == null) {
      const oldClickedRelation = editMode.relationEdit.clicked
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: {
            relationId: null, // automatically updates legend
            wayIds: [],
            tags: {}
          }
        }
      }

      dispatch(setEditModeAction(newEditModeState, map, editMode))

      // Update map
      const sourceId = newEditModeState.scenario.sourceId
      if (oldClickedRelation.relationId) {
        oldClickedRelation.wayIds.forEach(wayId => {
          map.setFeatureState(
            { source: sourceId, id: wayId },
            { hovered: false, clicked: false, selected: false }
          )
        })
      }
    }

    return {
      payload: activeKey
    }
  }
)

export const setDevModeAction = createAction('SET_DEV_MODE')

export const setRoadPropertyStylesAction = createAction('SET_ROAD_PROPERTY_STYLES')

export const setMapStyleAction = createAction('SET_MAP_STYLE')

export const deleteTrafficAction = createAction('DELETE_TRAFFIC_MAP')

export const deleteDifferenceAction = createAction('DELETE_DIFFERENCE_MAP')

export const addScenarioAction = createAction('ADD_SCENARIO')

export const addTrafficAction = createAction('ADD_TRAFFIC_MAP')

export const addDifferenceAction = createAction('ADD_DIFFERENCE_MAP')

export const deleteScenarioAction = createAction('DELETE_SCENARIO')
