import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setEditModeAction } from '../../../actions/defaultActions'
import { RelationModifications } from '../../constants/RelationModifications'
import RoadProperties from './RoadProperties'
import { addRelationColor, RelationColors, NetworkColors } from '../../constants/Colors'
import { patchScenario } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import EditRelationLegend from './EditRelationLegend'
import AddRelation from './AddRelation'

const EditRelation = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  const [open, setOpen] = useState(false)
  const [draft, setDraft] = useState({ name: '' })

  const onAddWays = () => {
    setModification({
      type: RelationModifications.AddingWays,
      hoveredWayId: null,
      selectedWayIds: []
    })
    setPaintColor(RelationColors.Add, RelationColors.HoveredAdd)
  }

  const onRemoveWays = () => {
    setModification({
      type: RelationModifications.RemovingWays,
      hoveredWayId: null,
      selectedWayIds: []
    })
    setPaintColor(RelationColors.Remove, RelationColors.HoveredRemove)
  }

  const onDelete = () => {
    setModification({
      type: RelationModifications.DeletingRelation,
      hoveredWayId: null,
      selectedWayIds: []
    })
  }

  const onDeleteCancel = () => {
    const modification = { type: null, hoveredWayId: null, selectedWayIds: [] }
    setModification(modification)
  }

  const onDeleteConfirm = () => {
    // We just delete all ways from the relation. This also delete the relation in the backend
    // if the relation was created by the user, not imported from OSM.
    sendChangesToApi(editMode.relationEdit.clicked.wayIds)

    applyToMapSource(editMode.relationEdit.clicked.wayIds)

    onCancel() // Reset view
  }

  const onSave = () => {
    sendChangesToApi(editMode.relationEdit.modification.selectedWayIds)

    applyToMapSource(editMode.relationEdit.modification.selectedWayIds)

    setModificationAndClickedWayIds(
      newWayIds(),
      { type: null, hoveredWayId: null, selectedWayIds: [] }
    )

    resetPaintColor()
  }

  const onCancel = () => {
    const newRelation = {
      relationId: null,
      wayIds: [],
      tags: null
    }
    const modification = { type: null, hoveredWayId: null, selectedWayIds: [] }
    setModificationAndClickedRelation(newRelation, modification)
    resetPaintColor()
  }

  // Keep function in sync with copy in useMapFeatureHandlers
  const changeClickedRelation = (editMode, newRelation, dispatch) => {
    dispatch(setEditModeAction({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: newRelation
      }
    }, map, editMode))
  }

  const onClose = () => {
    changeClickedRelation(editMode, {
      relationId: null,
      wayIds: [],
      tags: null
    }, dispatch)
  }

  const handlePopupSubmit = (e) => {
    e.preventDefault()
    if (draft.name.length === 0) {
      alert('Bitte geben Sie einen Namen ein.')
      return
    }
    // Reset popup fields and hide to prevent user interaction
    setOpen(false)
    setDraft({ name: '' })

    // Smaller than max java and mongo db int but large enought to not hit existing OSM relation ids
    const newRandomRelationId = Math.floor(100000000 + Math.random() * 900000000)
    const newRelation = {
      relationId: newRandomRelationId,
      // relationId: -1, // TODO: indicate, that this relation needs an id assigned by the server
      wayIds: [],
      tags: {
        type: 'route',
        route: 'bicycle',
        name: draft.name
      }
    }
    const modification = {
      type: RelationModifications.CreatingRelation,
      hoveredWayId: null,
      selectedWayIds: []
    }
    setModificationAndClickedRelation(newRelation, modification)
    setPaintColor(RelationColors.Add, RelationColors.HoveredAdd)
  }

  /**
   * Shows a popup for further input information.
   */
  const onCreate = () => {
    setOpen(true)
  }

  /**
   * Updates the internal state.
   */
  const onNameChanged = (e) => {
    setDraft({ name: e.target.value })
  }

  /**
   * Needs to be one call or else the second call will undo the first call.
   *
   * @param {*} newWayIds The new wayIds to be set.
   * @param {*} modification The new modification to be set.
   */
  const setModificationAndClickedWayIds = (newWayIds, modification) => {
    if (modification === null) {
      throw new Error('Modification cannot be null')
    }
    dispatch(setEditModeAction({
      ...editMode,
      scenarioChanged: true, // To remove the simulation on edit mode save
      relationEdit: {
        ...editMode.relationEdit,
        clicked: {
          ...editMode.relationEdit.clicked,
          wayIds: newWayIds
        },
        modification
      }
    }, map, editMode))
  }

  /**
   * Needs to be one call or else the second call will undo the first call.
   *
   * @param {*} newRelation The new relation to be set.
   * @param {*} modification The new modification to be set.
   */
  const setModificationAndClickedRelation = (newRelation, modification) => {
    if (modification === null) {
      throw new Error('Modification cannot be null')
    }
    dispatch(setEditModeAction({
      ...editMode,
      scenarioChanged: true, // To remove the simulation on edit mode save
      relationEdit: {
        ...editMode.relationEdit,
        clicked: newRelation,
        modification
      }
    }, map, editMode))
  }

  const setModification = (modification) => {
    if (modification === null) {
      throw new Error('Modification cannot be null')
    }
    dispatch(setEditModeAction({
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        modification
      }
    }, map, editMode))
  }

  const setPaintColor = (selectColor, hoveredColor) => {
    const color = [
      'case',
      ['boolean', ['feature-state', 'hovered'], false],
      hoveredColor,
      ['boolean', ['feature-state', 'selected'], false], // highest priority
      selectColor,
      ['boolean', ['feature-state', 'clicked'], false], // To keep the clicked relation color
      NetworkColors.Clicked,
      ['get', 'relationColor']
    ]
    map.setPaintProperty(editMode.scenario.layerId, 'line-color', color)
  }

  const resetPaintColor = () => {
    const style = roadPropertyStyles.find(style => style.key === RoadProperties.relations.key)
    map.setPaintProperty(editMode.scenario.layerId, 'line-color', style.colors)
  }

  const sendChangesToApi = async (selectedWayIds) => {
    await patchScenario(
      dispatch,
      defaultErrorHandling,
      logout,
      editMode.scenario.id,
      getChangeset(selectedWayIds)
    )
  }

  const getChangeset = (selectedWayIds) => {
    const modification = editMode.relationEdit.modification
    const changeset = { 'element-id': 'relation/' + editMode.relationEdit.clicked.relationId }
    if (modification.type === RelationModifications.AddingWays) {
      changeset.added = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    } else if (modification.type === RelationModifications.RemovingWays ||
        modification.type === RelationModifications.DeletingRelation
    ) {
      changeset.deleted = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    } else if (modification.type === RelationModifications.CreatingRelation) {
      changeset.created = true // indicates that this is not an edit of an existing relation
      changeset.edited = {
        type: 'route', // default for bicycle routes
        route: 'bicycle', // default for bicycle routes
        name: editMode.relationEdit.clicked.tags.name
      }
      changeset.added = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    }
    return changeset
  }

  const applyToMapSource = (selectedWayIds) => {
    const source = map.getSource(editMode.scenario.sourceId)
    const sourceData = source._data
    const updatedFeatures = addRelationColor({
      type: 'FeatureCollection',
      features: getUpdatedFeatures(selectedWayIds)
    })
    source.setData({
      ...sourceData,
      features: updatedFeatures.features
    })
  }

  const newWayIds = () => {
    const modification = editMode.relationEdit.modification

    switch (modification.type) {
      // Both modes only add ways
      case RelationModifications.AddingWays:
      case RelationModifications.CreatingRelation: {
        const updatedWayIds = [
          ...editMode.relationEdit.clicked.wayIds,
          ...modification.selectedWayIds
        ]
        return updatedWayIds
      }
      case RelationModifications.RemovingWays: {
        return editMode.relationEdit.clicked.wayIds.filter(wayId => {
          return !modification.selectedWayIds.includes(wayId)
        })
      }
      default: {
        throw new Error('Unknown modification type: ' + editMode.relationEdit.modification.type)
      }
    }
  }

  const getUpdatedFeatures = (selectedWayIds) => {
    const relation = {
      osmId: editMode.relationEdit.clicked.relationId,
      tags: editMode.relationEdit.clicked.tags
    }
    const sourceData = map.getSource(editMode.scenario.sourceId)._data

    return sourceData.features.map(feature => {
      const modification = editMode.relationEdit.modification
      const wayId = feature.properties['@id']
      if (!selectedWayIds.includes(wayId)) {
        return feature
      }
      const propertiesClone = { ...feature.properties } // clone as properties cannot be modified

      // Add or remove relation to/from ways
      const relations = feature.properties.relations || []
      if (modification.type === RelationModifications.AddingWays ||
          modification.type === RelationModifications.CreatingRelation) {
        propertiesClone.relations = relations.concat(relation)
      } else if (modification.type === RelationModifications.RemovingWays ||
          modification.type === RelationModifications.DeletingRelation
      ) {
        propertiesClone.relations = feature.properties.relations.filter(
          rel => rel.osmId !== editMode.relationEdit.clicked.relationId
        )
        if (propertiesClone.relations.length === 0) {
          delete propertiesClone.relations // required as property check is used in paint style
        }
      }

      return {
        ...feature,
        properties: propertiesClone
      }
    })
  }

  return (
    <div>
      <EditRelationLegend
        relationEdit={editMode.relationEdit}
        onAdd={onAddWays}
        onRemove={onRemoveWays}
        onSave={onSave}
        onCancel={onCancel}
        onClose={onClose}
        onCreate={onCreate}
        onDelete={onDelete}
        onDeleteCancel={onDeleteCancel}
        onDeleteConfirm={onDeleteConfirm}
      />
      <AddRelation
        open={open}
        setOpen={setOpen}
        draft={draft}
        onNameChanged={onNameChanged}
        handlePopupSubmit={handlePopupSubmit}
      />
    </div>
  )
}

EditRelation.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default EditRelation
