import React from 'react'
import PropTypes from 'prop-types'
import ScenarioEntry from './ScenarioEntry'
import { useSelector } from 'react-redux'

/**
 * The entries shown in the 'ScenariosView' of the sidebar.
 */
const Scenarios = ({ map, logout }) => {
  // Redux state
  const scenarios = useSelector((state) => state.scenarios)

  return (
    <div>
      {scenarios.length
        ? (
            scenarios.map(scenario => (
              <ScenarioEntry
                key={scenario.id}
                map={map}
                logout={logout}
                scenario={scenario}
              />
            ))
          )
        : (
        <p className="center">Keine Einträge vorhanden</p>
          )}
    </div>
  )
}

Scenarios.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default Scenarios
